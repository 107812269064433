.wound-area--wound {
  width: 100%;
  height: 69vh;
  position: relative;
  border: 1px solid #ccc;
  border-radius: 0.25rem;
}

.wound-area--wound-map {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.wound-area--wound-map {
  object-fit: contain;
  object-position: bottom;
  touch-action: none;
}

.ol-zoom .ol-zoom-out,
.ol-zoom .ol-zoom-in {
  font-size: 1.5rem;
}

.ol-control.enable-drag {
  top: .5em;
  right: .5em;
}

.ol-control button {
  cursor: pointer;
  font-size: 1.5rem;
}

.info {
  margin-top: 1rem;
  font-weight: 600;
}